import { encryptAuth } from 'assets/helpers/helpers';
import interceptor from './interceptor.service';
import { hardRefresh, shouldUpdate } from 'utils/hardRefresh';
import { updateUserInfo, shouldUpdateUserInfo } from 'utils/userInfo';
import { version } from 'version';
import { deleteAuthCookie } from 'helpers/auth';

function logout() {
  localStorage.removeItem('redirectionLocation');
  localStorage.removeItem('user');
  deleteAuthCookie();
}

function handleResponse(response) {
  if (shouldUpdateUserInfo(response)) updateUserInfo(response);
  if (shouldUpdate(response)) hardRefresh();
  if (response.status === 200) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      return { status: 200, data };
    });
  }
  switch (response.status) {
    case 422:
      logout();
      return { status: 422, data: null };
    case 401:
      logout();
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return { status: 401, data };
      });
    case 400:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return { status: 400, data };
      });
    case 403:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return { status: 403, data };
      });
    case 404:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return { status: 404, data };
      });
    case 500:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return { status: 500, data };
      });
    case 409:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return { status: 409, data };
      });
    default:
      console.error('Unknown status code');
      return { status: response.status, data: null };
  }
}

function login(email, password, url) {
  email = email.toString().toLowerCase();
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user: { email, password, url } }),
  };
  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/login`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      const user = data;
      if (user && status === 200) {
        localStorage.setItem('user', JSON.stringify(user));
        return user;
      }
      const error = { status, data };
      return Promise.reject(error);
    });
}

async function getEmailStatus(data) {
  data.email = data.email.toString().toLowerCase();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/emailstatus`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      try {
        return response.data;
      } catch (err) {
        return null;
      }
    });
}

function getUserInfo() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/profile`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data) return data;

      return null;
    });
}

function getUserInfoSettings() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/profile/settings`, requestOptions)
    .then(handleResponse)
    .then(({ data }) => {
      if (data) return data;

      return null;
    });
}

function uploadImages(imageParams) {
  const formData = new FormData();
  formData.append('profile_image', imageParams.profile_image);
  formData.append('background_image', imageParams.background_image);

  const requestOptions = {
    method: 'POST',
    headers: {
      Version: version,
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
    body: formData,
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/profile_images`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) return data;
      const error = { status, data };
      return Promise.reject(error);
    });
}

function uploadStatsImages(croppedImage, type) {
  const formData = new FormData();
  formData.append(type, croppedImage);

  const requestOptions = {
    method: 'POST',
    headers: {
      Version: version,
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
    body: formData,
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/audience_stats`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      const user = data;
      if (user && status === 200) {
        return user;
      }
      const error = { status, data };
      console.error(error);
      return Promise.reject(error);
    });
}

async function signup(userParams) {
  const data = JSON.parse(JSON.stringify(userParams));
  data.email = data.email ? encryptAuth(data.email) : null;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Version: version
    },
    body: JSON.stringify(data),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data?.token) {
        localStorage.setItem('user', JSON.stringify({ user: data }))
      }
      return { status, data };
    });
}

function updateUser(userParams) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
    body: JSON.stringify(userParams),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/profile`, requestOptions)
    .then(handleResponse)
    .then(({ data }) => {
      const userInfo = data;
      return userInfo;
    });
}

function updateLanguage(lang) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
    body: JSON.stringify({ lang }),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/language`, requestOptions)
    .then(handleResponse)
    .then(({ data }) => {
      const userInfo = data;
      return userInfo;
    });
}

function deleteIgAccount() {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
  };
  return interceptor(`${process.env.REACT_APP_API_URL}/connect/remove`, requestOptions)
    .then(handleResponse)
    .then((response) => response);
}

function userChangePassword(passwordParams) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
    body: JSON.stringify(passwordParams),
  };
  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/password`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (status === 200) {
        return data;
      }
      return { error: data.error };
    });
}

function sendNotificationsToken(notifToken, userAgent) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
    body: JSON.stringify({
      token: notifToken,
      user_agent: userAgent,
    }),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/device_token`, requestOptions)
    .then(handleResponse)
    .then(({ data }) => {
      if (data) return data;

      return null;
    });
}

function deleteAccount(reason) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
    body: JSON.stringify({
      reason,
    }),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (status === 200) return data;

      return null;
    });
}

function checkHandle(handle, network) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
    body: JSON.stringify({
      network,
    }),
  };

  return interceptor(
    `${process.env.REACT_APP_API_URL}/api/users/handleStatus/${encodeURIComponent(handle)}`,
    requestOptions,
  )
    .then(handleResponse)
    .then(({ status, data }) => {
      if (status === 200) return data;
      else return {};
    });
}

async function resendEmail(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Version: version,
      previous_email: data?.previous_email || null,
    },
  };
  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/resendEmail/${data.email}`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (status === 200) return data;
      else return {};
    });
}

async function sendSignupDetails(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
    body: JSON.stringify(data),
  };
  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/details`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (status === 200) return data;
      else return null;
    });
}

async function sendSignupBrandDetails(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
    body: JSON.stringify(data),
  };
  return interceptor(`${process.env.REACT_APP_API_URL}/api/users/brand/details`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (status === 200) return data;
      else return null;
    });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  handleResponse,
  login,
  signup,
  logout,
  getUserInfo,
  getEmailStatus,
  updateUser,
  updateLanguage,
  uploadImages,
  deleteIgAccount,
  userChangePassword,
  sendNotificationsToken,
  uploadStatsImages,
  deleteAccount,
  getUserInfoSettings,
  checkHandle,
  resendEmail,
  sendSignupDetails,
  sendSignupBrandDetails
};
