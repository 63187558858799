import React, { useEffect, Suspense, useState } from 'react';
import { useLocation, Outlet, matchPath } from 'react-router-dom';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import ProfileMenu from 'pages/ProfileMenu/ProfileMenu';
import Navbar from 'components/Navbar/Navbar';
import Loader from 'components/Loader/Loader';
import CustomErrorBoundary from 'utils/ErrorBoundary';
import PublishCampaignBanner from 'components/PublishCampaignBanner/PublishCampaignBanner';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ResultsCtxProvider } from 'pages/Results/contexts/results-ctx';
import { FiltersCtxProvider } from 'pages/Results/contexts/filters-ctx';
import { CollaborationsCtxProvider } from 'pages/MyCollaborations/contexts/collaborations-ctx';
import { MarketplaceCtxProvider } from 'pages/Opportunities/contexts/marketplace-ctx';
import { MobileMenuContextProvider } from 'context/mobileMenuContext';
import HasBannerContext from 'context/hasBannerContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import useGeoLocation from 'react-ipgeolocation';
import { hideIntercomButton, showIntercomButton } from 'assets/helpers/helpers';
import { ReportingCtxProvider } from 'pages/Reporting/Context/reporting-ctx';
import Survey from 'components/Survey/Survey';
import { SurveyCtxProvider } from 'components/Survey/contexts/survey-ctx';
import { getAmplitudeDeviceId } from 'utils/amplitude';
import { ChatCtxProvider } from 'pages/Chat/context/chat-context';
import RequestNetworksModal from 'components/RequestNetworksModal/RequestNetworksModal';
import { RequestNetworkCtxProvider } from 'components/RequestNetworksModal/context/request-network-context';
import { identifyUser, trackPageView } from 'utils/instrumentation';
import RequestBrandDetails from 'components/RequestBrandDetails/RequestBrandDetails';
import isProduction from 'helpers/isProduction';
import { deleteAuthCookie, getAuthCookie, setAuthCookie } from 'helpers/auth';

async function loadPolyfills() {
  await import('intersection-observer');
}
typeof window.IntersectionObserver === 'undefined' && loadPolyfills();

const NO_NAVBAR_PATH_PATTERNS = [
  '/verification',
  '/activation/:userEncoded',
  '/reviews/:userEncoded/:proposalEncoded',
  '/reviewDone',
  '/create_opportunity',
  '/accept_seat',
  '/reports/:reportId/share',
  '/reports/:reportId/pdf',
];

const RouterLayout = ({ isErrorPage, setIsErrorPage }) => {
  const location = useLocation();
  const { i18n } = useTranslation('common');
  const { t } = useTranslation('common');
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  const selectedLanguage = localStorage.getItem('lang') || 'en';
  const [hasBanner, setHasBanner] = useState(false);
  const IpLocation = useGeoLocation();
  
  const hideNavbar = NO_NAVBAR_PATH_PATTERNS.some((pattern) => matchPath(pattern, location.pathname));

  useEffect(() => {
    document.body.style.overflowY = 'scroll';
    if (['/collaborations', '/card'].includes(location.pathname) && !isMobile) {
      hideIntercomButton();
    } else if (!isMobile) {
      showIntercomButton();
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const identifySegment = (deviceId) => {
    if (!user?.token) return;
    const traits = {
      email: user?.email,
      user_type: user?.account_type,
      moonio_name: user?.full_name,
    };
    const options = { context: { device: { id: deviceId } } };
    identifyUser(user.id, traits, options);
  };

  useEffect(() => {
    if (!IpLocation.isLoading) {
      localStorage.setItem('ipLocation', IpLocation.country);
    }
  }, [IpLocation.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleLanguage = () => {
      const queryLang = new URLSearchParams(location.search).get('lng');
      const userLang = JSON.parse(localStorage.getItem('user'))?.user?.lang;
      const i18nLang = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').split('-')[0] : 'en';
      const lang = queryLang || userLang || i18nLang;
      localStorage.setItem('lang', lang);
      if (lang && lang !== i18n.language) i18n.changeLanguage(userLang);
    };
    handleLanguage();
    const amplitudeDeviceId = getAmplitudeDeviceId();

    identifySegment(amplitudeDeviceId);

    const urlParams = new URLSearchParams(location.search);
    trackPageView(
      {
        session_utm_source: urlParams.get('utm_source'),
        session_utm_medium: urlParams.get('utm_medium'),
        session_utm_campaign: urlParams.get('utm_campaign'),
        custom_referrer: document.referrer,
      },
      { context: { device: { id: amplitudeDeviceId } } },
    );

    document.documentElement.style.overflowY = ['/collaborations'].includes(location.pathname) ? 'hidden' : 'scroll';
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const startSmartlookRecording = () => {
      if (!window.smartlook) return;
      window.smartlook('record', {
        forms: true,
        emails: true,
        ips: true,
        numbers: true,
      });
    };
    const startSmartlookRecordingTimeout = setTimeout(() => startSmartlookRecording(), 2000);
    
    return () => clearTimeout(startSmartlookRecordingTimeout);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleAccept = () => {
      const amplitudeDeviceIdLocalStorage = localStorage.getItem('amplitudeDeviceId');
      if (amplitudeDeviceIdLocalStorage) {
        const { hostname } = window.location;
        const domain = hostname === 'localhost' ? 'localhost' : '.heepsy.com';
        const ONE_YEAR_IN_SECONDS = 365 * 24 * 60 * 60;
        document.cookie = `amplitudeDeviceId=${amplitudeDeviceIdLocalStorage};domain=${domain};max-age=${ONE_YEAR_IN_SECONDS};path=/`;
      }
    };
    
    window.addEventListener('CookiebotOnAccept', handleAccept);
    window.addEventListener('CookiebotOnDecline', handleAccept);

    return () => {
      window.removeEventListener('CookiebotOnAccept', handleAccept);
      window.removeEventListener('CookiebotOnDecline', handleAccept);
    };
  }, []);

  useEffect(() => {
    if (user?.token) {
      const authCookie = getAuthCookie();
      if (!authCookie) {
        setAuthCookie(user.token);
      }
    } else {
      deleteAuthCookie();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_VERIFICATION_CLIENT_ID}>
      <MarketplaceCtxProvider>
        <ResultsCtxProvider>
          <FiltersCtxProvider>
            <CollaborationsCtxProvider>
              <ReportingCtxProvider>
                <ChatCtxProvider>
                  <HelmetProvider>
                    <MobileMenuContextProvider>
                      <HasBannerContext.Provider value={hasBanner}>
                        <RequestNetworkCtxProvider>
                          <Helmet>
                            <meta
                              name="description"
                              content={
                                selectedLanguage === 'es'
                                  ? 'Heepsy pone en contacto a negocios de toda clase con creadores de contenido de calidad, para todo tipo de colaboraciones en canales de Social Media. La misión de Heepsy es ayudarte a cerrar acuerdos de éxito de una manera sencilla.'
                                  : 'Heepsy connects businesses of all sizes with powerful social media trendsetters for all types of collaborations. Heepsy\'s mission is to help you find collaborations easily"'
                              }
                            />
                            {!isProduction() && <meta name="robots" content="noindex, follow" />}
                            {isProduction() && window.location.href.includes('/pricing') && <link rel="canonical" href="https://go.heepsy.com/pricing" />}
                            <title>{t('title')}</title>
                          </Helmet>
                          {isMobileOnly && <ProfileMenu />}
                          <Suspense fallback={<Loader />}>
                            <CustomErrorBoundary>
                              <Outlet />
                            </CustomErrorBoundary>
                          </Suspense>
                          {hasBanner &&
                            !['/create_opportunity'].includes(location.pathname) &&
                            user?.token &&
                            user?.account_type === 'brand' && <PublishCampaignBanner setHasBanner={setHasBanner} />}
                          {!hideNavbar && <Navbar isErrorPage={isErrorPage} setIsErrorPage={setIsErrorPage} />}
                          <SurveyCtxProvider>
                            <Survey />
                          </SurveyCtxProvider>

                          <RequestNetworksModal />
                          <RequestBrandDetails />
                        </RequestNetworkCtxProvider>
                      </HasBannerContext.Provider>
                    </MobileMenuContextProvider>
                  </HelmetProvider>
                </ChatCtxProvider>
              </ReportingCtxProvider>
            </CollaborationsCtxProvider>
          </FiltersCtxProvider>
        </ResultsCtxProvider>
      </MarketplaceCtxProvider>
    </GoogleOAuthProvider>
  );
};

export default RouterLayout;
