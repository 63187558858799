import React, { createContext, useReducer, useMemo } from 'react';

export const PipelinesCtx = createContext();

const initialState = {
  checkStatus: [],
  allChecked: false,
  pipelineCreatorsContactInfo: [],
  cardsUnlockedCreatorStatus: {},
  cardsContactStatus: {},
  moonioIdsFromProfileIds: {},
  isCardsFetchingEnabled: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_CHECKED_STATUS':
      return {
        ...state,
        checkStatus: action.payload,
      };
    case 'SET_ALL_CHECKED':
      return {
        ...state,
        allChecked: action.payload,
      };
    case 'ADD_CREATORS_CONTACT_INFO':
      return {
        ...state,
        pipelineCreatorsContactInfo: [
          ...state.pipelineCreatorsContactInfo,
          ...action.payload,
        ],
      };
    case 'ADD_CARDS_UNLOCKED_CREATOR_STATUS':
      return {
        ...state,
        cardsUnlockedCreatorStatus: {
          ...state.cardsUnlockedCreatorStatus,
          ...action.payload,
        },
      };
    case 'ADD_CARDS_CONTACT_STATUS':
      return {
        ...state,
        cardsContactStatus: {
          ...state.cardsContactStatus,
          ...action.payload,
        },
      };
    // case 'RESET_CHECKED_CARDS_AND_STAGES':
    //   return {
    //     ...state,
    //     checkedCards: initialState.checkedCards,
    //     checkedStages: initialState.checkedStages,
    //   };
    case 'SET_CARDS_FETCHING_ENABLED':
      return {
        ...state,
        isCardsFetchingEnabled: action.payload,
      };
    case 'ADD_CARDS_MOONIO_IDS':
      return {
        ...state,
        moonioIdsFromProfileIds: {
          ...state.moonioIdsFromProfileIds,
          ...action.payload,
        },
      };
    case 'RESET_STORE':
      return initialState;
    default:
      return state;
  }
}

export function PipelinesCtxProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <PipelinesCtx.Provider value={value}>{children}</PipelinesCtx.Provider>;
}
