import { useContext, useState } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import usersService from 'services/users.service';
import { UserDetails } from 'pages/SignupDetails/components/UserDetails';
import { RequestNetworkCtx } from './context/request-network-context';

import './request-networks-modal.scss';

export default function RequestNetworksModal() {
  const { t } = useTranslation('common');
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  const { state } = useContext(RequestNetworkCtx);

  const [isOpened, setIsOpened] = useState(user?.needs_networks);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmission = async () => {
    const values = {
      name: state.name,
      instagram_username: state.igHandle,
      youtube_username: state.ytHandle,
      tiktok_username: state.ttHandle,
      cost_per_post_instagram: state.cost_per_post_instagram,
      cost_per_post_youtube: state.cost_per_post_youtube,
      cost_per_post_tiktok: state.cost_per_post_tiktok,
      cost_per_reels_instagram: state.cost_per_reels_instagram,
      cost_per_story_instagram: state.cost_per_story_instagram,
      currency_instagram: state.currency_instagram,
      currency_youtube: state.currency_youtube,
      currency_tiktok: state.currency_tiktok,
    };

    setIsLoading(true);
    const user = await usersService.sendSignupDetails(values);
    if (!user) {
      message.error(t('lists.error'));
      setIsLoading(false);
      return;
    }
    message.success(t('request-networks-modal.success'));
    setIsOpened(false);
    localStorage.setItem('user', JSON.stringify({ user }));
    setIsLoading(false);
  };

  const enableSubmitBtn =
    !!state.selectedNetworks.length &&
    state.name &&
    Object.values(state.isHandleInvalid).every((item) => item === false) &&
    ((state.igHandle && !state.igHandleHasError) ||
      (state.ytHandle && !state.ytHandleHasError) ||
      (state.ttHandle && !state.ttHandleHasError)) &&
    (!state.igHandle || !state.igHandleHasError) &&
    (!state.ytHandle || !state.ytHandleHasError) &&
    (!state.ttHandle || !state.ttHandleHasError);

  return (
    <Modal
      title={t('request-networks-modal.title')}
      open={isOpened}
      centered
      onCancel={() => setIsOpened(false)}
      width={450}
      className="request-networks-modal"
      okButtonProps={{ loading: isLoading, disabled: !enableSubmitBtn }}
      onOk={handleSubmission}
      okText={t('invite_modal.save')}
      cancelText={t('invite_modal.cancel')}
    >
      <UserDetails />
    </Modal>
  );
}
