import React, { createContext, useReducer } from 'react';

export const RequestNetworkCtx = createContext();

const initialState = {
  igHandle: '',
  ttHandle: '',
  ytHandle: '',
  igHandleHasError: false,
  ttHandleHasError: false,
  ytHandleHasError: false,
  igHandleError: '',
  ttHandleError: '',
  ytHandleError: '',
  isHandleInvalid: { instagram: false, tiktok: false, youtube: false },
  currency_instagram: 'usd',
  currency_tiktok: 'usd',
  currency_youtube: 'usd',
  cost_per_post_instagram: 0,
  cost_per_post_tiktok: 0,
  cost_per_post_youtube: 0,
  cost_per_story_instagram: 0,
  cost_per_reels_instagram: 0,
  name: '',
  selectedNetworks: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_IG_HANDLE':
      return {
        ...state,
        igHandle: action.payload,
      };
    case 'SET_TT_HANDLE':
      return {
        ...state,
        ttHandle: action.payload,
      };
    case 'SET_YT_HANDLE':
      return {
        ...state,
        ytHandle: action.payload,
      };
    case 'SET_IG_HANDLE_HAS_ERROR':
      return {
        ...state,
        igHandleHasError: action.payload,
        isHandleInvalid: {
          ...state.isHandleInvalid,
          instagram: action.payload,
        },
      };
    case 'SET_TT_HANDLE_HAS_ERROR':
      return {
        ...state,
        ttHandleHasError: action.payload,
        isHandleInvalid: {
          ...state.isHandleInvalid,
          tiktok: action.payload,
        },
      };
    case 'SET_YT_HANDLE_HAS_ERROR':
      return {
        ...state,
        ytHandleHasError: action.payload,
        isHandleInvalid: {
          ...state.isHandleInvalid,
          youtube: action.payload,
        },
      };
    case 'SET_IG_HANDLE_ERROR':
      return {
        ...state,
        igHandleError: action.payload,
      };
    case 'SET_TT_HANDLE_ERROR':
      return {
        ...state,
        ttHandleError: action.payload,
      };
    case 'SET_YT_HANDLE_ERROR':
      return {
        ...state,
        ytHandleError: action.payload,
      };
    case 'SET_IS_HANDLE_INVALID':
      return {
        ...state,
        isHandleInvalid: {
          ...state.isHandleInvalid,
          [action.payload.network]: action.payload.value,
        },
      };
    case 'SET_CURRENCY_INSTAGRAM':
      return {
        ...state,
        currency_instagram: action.payload,
      };
    case 'SET_CURRENCY_TIKTOK':
      return {
        ...state,
        currency_tiktok: action.payload,
      };
    case 'SET_CURRENCY_YOUTUBE':
      return {
        ...state,
        currency_youtube: action.payload,
      };
    case 'SET_COST_PER_POST_INSTAGRAM':
      return {
        ...state,
        cost_per_post_instagram: action.payload,
      };
    case 'SET_COST_PER_POST_TIKTOK':
      return {
        ...state,
        cost_per_post_tiktok: action.payload,
      };
    case 'SET_COST_PER_POST_YOUTUBE':
      return {
        ...state,
        cost_per_post_youtube: action.payload,
      };
    case 'SET_COST_PER_STORY_INSTAGRAM':
      return {
        ...state,
        cost_per_story_instagram: action.payload,
      };
    case 'SET_COST_PER_REELS_INSTAGRAM':
      return {
        ...state,
        cost_per_reels_instagram: action.payload,
      };
    case 'SET_NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'SET_SELECTED_NETWORKS':
      return {
        ...state,
        selectedNetworks: action.payload,
      };
    case 'RESET_STORE':
      return initialState;
    default:
      return state;
  }
}

export function RequestNetworkCtxProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <RequestNetworkCtx.Provider value={value}>{children}</RequestNetworkCtx.Provider>;
}
